import {TicketGroupT} from '../external_usage';

export const mobileRoutes = {
  groups: '/m-groups',
  send: '/m-send-ticket',
  guests: '/m-guest-list',
  guestEdit: '/m-guest-edit',
};

export const getSendLink = (group?: Partial<TicketGroupT>) => mobileRoutes.send + `?groupId=${group?.id}`;
export const getGuestLink = (group?: Partial<TicketGroupT>) => mobileRoutes.guests + `?groupId=${group?.id}`;
export const getEditGuestLink = (groupId?: string | number, guestId?: string | number) =>
  mobileRoutes.guestEdit + `?id=${groupId}&guestId=${guestId}`;
