import styled, {css} from 'styled-components';
import {Typography, Form} from 'antd';
import {Button} from '../../ui-kit/Button';
import Input, {FormItem} from '../../ui-kit/Input';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {ArrowLeftOutlined} from '@ant-design/icons';
const {Title, Text} = Typography;
import {LinkButton} from '../../ui-kit/Button';
import {Media} from '../../ui-kit/theme/breakpoints';
import goldCorner from '../../ui-kit/AppLayout/assets/goldcorner.png';
import trees from '../../ui-kit/AppLayout/assets/treesgold.png';
import CokeBackground from '../../ui-kit/AppLayout/assets/CC_Background_1.png';
import {themes} from '../../ui-kit/theme/theme';

export const PageWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.white};
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  min-height: 100vh;
  font-family: Proxima Nova;
  ${Media.down.l} {
    flex-direction: column;
    min-height: unset;
    height: 100%;
  }
`;

const enchantBG = css`
  background-color: ${({theme}) => theme.palette.background.midnightBlue};
  background-image: url(${goldCorner}), url(${trees});
  background-size: 70%, 20%;
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  img {
    max-width: 300px;
  }
  ${Media.down.l} {
    display: none;
  }
`;
const cokeBG = css`
  background-color: ${({theme}) => theme.palette.background.white};
  background-image: url(${CokeBackground});
  background-size: cover;
  background-repeat: no-repeat;
  img {
    max-width: 300px;
  }
  ${Media.down.l} {
    display: none;
  }
`;

export const LogoSectionWrapper = styled.div`
  width: 100%;
  min-height: 788px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({theme}) => {
    if (theme.current === themes.enchant) return enchantBG;
    if (theme.current === themes.coke) return cokeBG;
  }}
`;

export const Logo = styled.img`
  width: 273px;
  height: 56px;
  margin: 0 auto;
  margin-top: 50px;
  display: block;
`;

export const RightPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  ${Media.down.l} {
    padding: 10px 15px 20px 15px;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const LoginFormWrapper = styled(Form)`
  width: 100%;
  max-width: 365px;
`;

export const LoginFormTitle = styled(Title).attrs({
  level: 3,
})`
  font-family: 'Majesti Banner';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #141414;
  margin-bottom: 8px;
`;

export const LoginFormSubtitle = styled(Text)`
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  display: block;
  margin-bottom: 32px;
`;

export const LogoSectionTitle = styled(Title).attrs({
  level: 3,
})`
  &.ant-typography {
    font-family: 'Majesti Banner';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    background: linear-gradient(
      360deg,
      #b9976a 0%,
      #c8ab78 11.46%,
      #e4d19d 33.33%,
      #f9ecc8 52.08%,
      #e4d19d 68.23%,
      #d8c288 85.42%,
      #d4b67a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 12px;
  }
`;

export const StyledAuthInput = styled(Input)`
  &:hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
  }
  &.ant-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
    box-shadow: none;
  }
  &.ant-input.ant-input-status-success.ant-input:focus {
    box-shadow: none;
    border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  }
`;

export const StyledAuthFormItem = styled(FormItem)`
  && {
    margin-bottom: 16px;
  }
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.palette.common.redBrown};
    font-size: 10px;
    margin-bottom: 8px;
  }
  .ant-form-item-required {
    width: 100%;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ForgotFormWrapper = styled.div`
  a {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    color: ${({theme}) => theme.palette.common.brown};
    margin-top: 14px;
  }
`;

export const SubmitBtn = styled(Button).attrs({
  type: 'primary',
})`
  width: 93px;
  height: 40px;
  opacity: 1;
  :disabled {
    color: ${({theme}) => theme.palette.common.white};
    background: ${({theme}) => {
      if (theme.current === themes.coke) return theme.palette.background.black;
      return theme.palette.common.brown;
    }};
    opacity: 0.8;
  }
  ${Media.down.l} {
    display: block;
    margin: 0 auto;
  }
`;

export const FooterWrapper = styled(Footer)`
  height: 20px;
  padding-bottom: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 16px;
`;

export const ErrorLine = styled.div`
  height: 19px;
  margin-top: -15px;
`;
export const ErrorText = styled(Text)`
  font-size: 10px;
  color: ${({theme}) => theme.palette.common.redBrown};
  position: absolute;
`;

export const ChosePortalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GoPortalBtn = styled(Button)`
  margin-left: 10px;
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const ArrowBackIcon = styled(ArrowLeftOutlined)`
  margin-right: 10px;
  color: ${({theme}) => theme.palette.text.primary};
`;

export const StyledLine = styled.div`
  position: relative;
  border-bottom: 1px solid #9a9fa2;
  margin-top: 18px;
  margin-bottom: 34px;
`;

export const StyledOr = styled.span`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 24px;
  background-color: ${({theme}) => theme.palette.common.white};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
`;

export const LoginLinkButton = styled(LinkButton)`
  width: 223px;
  height: 40px;
  background-color: transparent;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #495258;
  ${Media.down.l} {
    display: block;
  }
`;
