import {css} from 'styled-components';
export const removeEmptyKeys = <T>(obj: any): T => {
  const result = {};
  Object.keys(obj).forEach((key) => {
    if (!!obj?.[key]) {
      // eslint-disable-next-line
            // @ts-ignore
      result[key] = obj[key];
    }
  });
  return result as T;
};

export const uArray = (array?: (string | undefined)[]) => {
  if (!array) return undefined;
  const out = [];
  for (let i = 0, len = array.length; i < len; i++) if (out.indexOf(array[i]) === -1) out.push(array[i]);
  return out;
};

export const MAX_TICKETS = 15;

export const parseError = (e: unknown) => {
  const error = JSON.parse(JSON.stringify(e));
  const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
  return errorMessage || '';
};

export const getDifferenceInDays = (d1: Date, d2: Date) => {
  return (d2.getTime() - d1.getTime()) / (1000 * 3600 * 24);
};

export const buttonReset = css`
  border: none;
  outline: none;
  background-color: transparent;
`;

export const IsMobileVersion = window.innerWidth < 1024;
