import React from 'react';
import ENC_logo from '../../ui-kit/AppLayout/assets/ENC_Logo_silver.png';
import {LogoSectionWrapper} from './styles';
import {themes} from '../../ui-kit/theme/theme';
import {THEME} from '../../constants/env';

const LogoMap = {
  [themes.enchant]: ENC_logo,
  [themes.coke]: '',
};

export const LogoSection: React.FC = () => {
  return <LogoSectionWrapper>{LogoMap?.[THEME] && <img src={LogoMap[THEME]} />}</LogoSectionWrapper>;
};
