import React from 'react';
import {
  DistributionPageWrapper,
  DistributionContentWrapper,
  ModalTitle,
  MethodBTN,
  GroupsSelect,
  LoadingWrapper,
} from './styles';
import email from '../../ui-kit/icons/email.svg';
import pdf from '../../ui-kit/icons/pdf.svg';
import groupPdf from '../../ui-kit/icons/groupPdf.svg';
import {FootNote, FootNoteBoldText, FootNoteText} from '../Welcome/styles';
import {TicketGroupT} from '../../queries/types/groups';
import {Select} from 'antd';
import {Loader} from '../../ui-kit/Loader';
import {TITLE} from '../../constants/text';

type DistributionPropsT = {
  groups: TicketGroupT[];
  goGroups: () => void;
  method?: string;
  selectEmail: () => void;
  selectPdf: () => void;
  generatePdf: (groupId?: string, individual?: boolean) => () => Promise<void>;
  selectedGroup?: number;
  onChangeGroup: (value: any) => void;
  metricsLoading?: boolean;
};

export const Distribution: React.FC<DistributionPropsT> = ({
  groups,
  selectEmail,
  selectPdf,
  method,
  generatePdf,
  selectedGroup,
  onChangeGroup,
  metricsLoading,
}) => {
  const options = groups?.map((el) => ({label: el?.clientName, value: el?.id}));
  const title = method === 'pdf' ? 'Choose A Format For Your Download' : 'How would you like to Issue tickets?';
  return (
    <DistributionPageWrapper>
      <DistributionContentWrapper>
        {groups?.length > 1 && (
          <GroupsSelect>
            <span>Group: </span>
            <Select options={options} value={selectedGroup} onChange={onChangeGroup} />
          </GroupsSelect>
        )}
        <ModalTitle>{title}</ModalTitle>
        {metricsLoading && (
          <LoadingWrapper>
            <Loader />
          </LoadingWrapper>
        )}
        {!method && !metricsLoading && (
          <>
            <MethodBTN
              onClick={selectEmail}
              img={email}
              label={'Suggested'}
              title={'By Email'}
              descr={
                'We recommend this method so your guests can access a digital ticket and display via phone to enter the event.'
              }
            />
            <MethodBTN
              onClick={selectPdf}
              img={pdf}
              title={'By PDF'}
              descr={
                'Create one PDF file to print all tickets, or generate a zip file that contains individual PDFs per-ticket.'
              }
            />
          </>
        )}
        {method === 'pdf' && !metricsLoading && (
          <>
            <MethodBTN
              onClick={generatePdf(String(selectedGroup), false)}
              img={pdf}
              label={'Suggested'}
              title={'Single PDF'}
              descr={
                'All tickets will be issued in one PDF file. This can be printed as a whole document with tickets on each page.'
              }
            />
            <MethodBTN
              onClick={generatePdf(String(selectedGroup), true)}
              img={groupPdf}
              title={'Individual PDFs'}
              descr={
                'Each ticket will be issued as a separate PDF file, and downloaded as a zip file containing all PDFs. These can be printed or sent individually to guests.'
              }
            />
          </>
        )}
        <FootNote>
          <FootNoteText>By continuing, I agree to {TITLE} </FootNoteText>
          <FootNoteBoldText href={'https://www.enchantchristmas.com/terms-conditions'} target={'_blank'}>
            terms and conditions{' '}
          </FootNoteBoldText>
          <FootNoteText>and </FootNoteText>
          <FootNoteBoldText href={'https://www.enchantchristmas.com/privacy-policy/'} target={'_blank'}>
            privacy policy.
          </FootNoteBoldText>
        </FootNote>
      </DistributionContentWrapper>
    </DistributionPageWrapper>
  );
};
