import React, {useState} from 'react';
import styled from 'styled-components';
import {TicketGroupT, getTicketsCountText} from '../../../external_usage';
import {fontPrimaryLight} from '../../../../ui-kit/theme/font';

type PropsT = {
  group?: TicketGroupT;
  openByDefault?: boolean;
  canExpand?: boolean;
};

export const GroupInfoExpander: React.FC<PropsT> = ({group, openByDefault = false, canExpand = true}) => {
  const [expanded, setExpanded] = useState(openByDefault);
  const toggleExpand = () => setExpanded((prev) => !prev);
  const buttonText = expanded ? 'Hide Details' : 'View Details';
  if (!group) return null;
  return (
    <Wrapper>
      <Line>
        <Label>Group Name</Label>
        <Value>{group?.clientName}</Value>
      </Line>
      {expanded && (
        <>
          <Line>
            <Label>Ticket Types</Label>
            <Value>{group?.ticketType.replaceAll(',', ', ')}</Value>
          </Line>
          <Line>
            <Label>Details</Label>
            <Value>Order Number: {group?.ticketureOrderNumber}</Value>
            <Value>
              All tickets are applicable {group?.details?.sellerVenue} in {group?.details?.sellerName} only
            </Value>
            <Value>{getTicketsCountText(group?.ticketTypes)}</Value>
            <div>-</div>
            <Value>{group?.description}</Value>
          </Line>
        </>
      )}
      {canExpand && <ExpandButton onClick={toggleExpand}>{buttonText}</ExpandButton>}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  gap: 4px;
  background: #f5f5f6;

  display: flex;
  flex-direction: column;
  gap: 16px;

  position: relative;
`;

const Line = styled.div``;

const Label = styled.div`
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: left;
  text-transform: uppercase;
  color: #9a9fa2;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #020e16;
  ${fontPrimaryLight}
`;

const ExpandButton = styled.div`
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #020e16;
  position: absolute;
  right: 16px;
  top: 26px;
`;
