import styled, {css} from 'styled-components';
import {Typography} from 'antd';
import {MethodButtonComponent} from '../Groups/View/OnboardingModals';
import {UploadOutlined} from '@ant-design/icons';
import {Button} from '../../ui-kit/Button';
const {Text} = Typography;
import goldOrnaments1 from '../../ui-kit/icons/gold_ornaments1.svg';
import goldOrnaments2 from '../../ui-kit/icons/gold_ornaments2.svg';
import CC_Background from '../../ui-kit/AppLayout/assets/CC_Background_2.png';
import {themes} from '../../ui-kit/theme/theme';

const encBG = css`
  background-color: ${({theme}) => theme.palette.background.midnightBlue};
  background-image: url(${goldOrnaments1}), url(${goldOrnaments2});
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
`;

const ccBG = css`
  background-color: ${({theme}) => theme.palette.background.white};
  background-image: url(${CC_Background});
  background-size: cover;
`;
export const DistributionPageWrapper = styled.div`
  ${({theme}) => {
    if (theme.current === themes.enchant) return encBG;
    if (theme.current === themes.coke) return ccBG;
  }}

  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100vh;
`;

export const DistributionContentWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.white};
  margin-top: 80px;
  padding: 68px 30px;
  border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  border-radius: 8px;
  width: 550px;
  height: 100%;
  text-align: center;
  position: relative;
`;

export const ModalTitle = styled(Text)`
  font-size: 24px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  line-height: 32px;
  margin-bottom: 56px;
  display: block;
  text-transform: capitalize;
`;

export const MethodBTN = styled(MethodButtonComponent)`
  width: 100%;
  min-height: 120px;
  height: auto;
`;

export const GroupsSelect = styled.div`
  position: absolute;
  right: 10px;
  top: 12px;
  .ant-select {
    font-size: 12px;
  }
`;

export const UploadingModalWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.white};
  margin-top: 80px;
  padding: 28px 24px;
  border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  border-radius: 8px;
  width: 498px;
  height: 100%;
  position: relative;
`;

export const UploadingWindow = styled.div`
  padding: 34px 20px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  border-radius: 5px;
`;

export const UploadTitle = styled(Text)<{$loading?: boolean}>`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: block;
  margin-bottom: 8px;
  font-weight: ${({theme, $loading}) => ($loading ? theme.font.weight.bold : theme.font.weight.regular)};
`;

export const UploadDescr = styled(Text).attrs({
  type: 'secondary',
})<{$loading?: boolean}>`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  display: block;
  color: ${({theme, $loading}) => ($loading ? `#333 !important` : theme.palette.common.gray)};
`;

export const UploadIconRow = styled.div.attrs({
  className: 'ant-upload-drag-icon',
})`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  .anticon {
    font-size: 42px;
  }
  .anticon-close-circle {
    color: ${({theme}) => theme.palette.common.red};
  }
  .anticon-check-circle {
    color: ${({theme}) => theme.palette.common.darkGreen};
  }
`;

export const UploadIcon = styled(UploadOutlined)<{$woTheme?: boolean}>`
  color: ${({theme, $woTheme}) => ($woTheme ? `#937E6B` : theme.palette.common.brown)};
  font-size: 40px;
`;

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ActionButton = styled(Button)<{$wide?: boolean}>`
  width: ${({$wide}) => ($wide ? '100%' : '49%')};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    margin-right: 10px;
  }
  font-size: 12px;
  margin-right: 0 !important;
  height: 40px;
  font-weight: bold;
`;

export const LoadingWrapper = styled.div`
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
