import React from 'react';
import {HeaderComponent} from '../../components/Layout/Header';
import {useLogOut, useViewer} from '../../external_usage';
import {useLocation, useNavigate} from 'react-router-dom';
import {mobileRoutes} from '../../constants/routes';
import {route} from '../../../constants/routes';

const hideGoBack = [mobileRoutes.groups, route.login.path];
export const MobileHeader = () => {
  const isAuth = useViewer();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const logout = useLogOut();

  const handleLogout = isAuth ? logout : undefined;
  const goBack = hideGoBack.some((el) => el.includes(pathname)) ? undefined : () => navigate(-1);

  return <HeaderComponent handleLogout={handleLogout} goBack={goBack} />;
};
