import React, {useEffect} from 'react';
import {WelcomePage} from '../../components/Welcome';
import {useViewer} from '../../hooks/auth';
import {useGetCsv, useGetGroups, useGetTicketGroup} from '../../hooks/groups';

export const Welcome = () => {
  const user = useViewer();
  const {groups} = useGetGroups();
  const lastGroup = groups?.[0]?.id || '';
  const {metrics} = useGetTicketGroup(String(lastGroup));
  const {ref, onDownload} = useGetCsv(String(lastGroup));
  useEffect(() => {
    lastGroup && onDownload();
  }, [!!lastGroup]);
  return <WelcomePage user={user} metrics={metrics} group={groups?.[0]} buttonRef={ref} />;
};
